<template>
    <div class="processArea">
        <div class="Procedure" :class="{'noneBusinessProcessId': !isBusinessProcessId }">
            <div class="flowChartBox">
                <div class="btns">
                    <template v-if="currentViewData.businessRemindList.length > 0">
                        <el-badge :value="currentViewData.businessRemindList.length" class="item">
                            <el-button
                                plain
                                type="warning"
                                icon="el-icon-warning"
                                v-popover:showTips
                                @click="isShowTips = true"
                            ></el-button>
                        </el-badge>
                    </template>
                    <el-button
                        plain
                        type="info"
                        class="FlowBhartBut iconfont iconliucheng"
                        @click="openflowChart"
                    ></el-button>
                </div>
                <el-popover
                    width="400"
                    trigger="click"
                    ref="showTips"
                    placement="bottom-end"
                    popper-class="flowPathTips"
                    v-model="isShowTips"
                >
                    <ul>
                        <template v-for="(item, index) in currentViewData.businessRemindList">
                            <li :key="`tips_${index}`">
                                <div>
                                    <i class="state" v-if="item.RecordStatus == 0">最新</i>
                                    <i class="state read" v-if="item.RecordStatus == 1">已读</i>
                                    <p>{{ item.RemindContent }}</p>
                                </div>
                                <p class="iconfont iconshijianxiao">
                                    {{ item.CreateDate }}
                                </p>
                            </li>
                        </template>
                    </ul>
                    <el-button
                        plain
                        type="primary"
                        size="small"
                        @click="flowPathTipsRead"
                    >
                        我知道了
                    </el-button>
                </el-popover>
                <a href="javascript:void(0)" v-if="btnHide">
                    <i class="el-icon-arrow-up"></i>
                </a>
                <div id="flowChart">
                    <div class="flowChartList">
                        <template v-for="(item,index) in currentViewData.historyNewRecordList">
                            <div class="procedureList" :class="{'countersignatureNode': item.node_type ==3}" :key="index">
                                <h3 class="countersignatureTitle" v-if="item.node_type ==3">
                                    <i class="iconfont icon05qunzufill"></i>
                                    会签审核
                                </h3>
                                <template v-for="(k, i) in item.data">
                                    <div class="listBox" :key="i">
                                        <h4>
                                            <span>{{ k.Content }}</span>
                                        </h4>
                                        <div class="userInfo">
                                            <span class="headPortrait el_left">
                                                <img src="@/components/businessInstance/processArea/images/icon4.png" alt="头像">
                                                <i class="icon"></i>
                                            </span>
                                            <div class="infoBox">
                                                <div>
                                                    <span class="name el_left">{{ k.ApproverName }}</span>
                                                    <span class="time el_right">{{ k.EndTime }}</span>
                                                </div>
                                                <span class="state el_left">{{ k.ParticipantAction }}</span>
                                            </div>
                                        </div>
                                        <ul v-if="k.CommentList != null">
                                            <template v-for="(z, j) in k.CommentList">
                                                <li :key="j">
                                                    <h5 class="iconfont iconshinshopxiaoxi">
                                                        {{ z.Content }}
                                                    </h5>
                                                    <div>
                                                        <span class="iconfont iconkehu1 commentUser">{{ z.CreateName }}</span>
                                                        <span class="iconfont iconshijianxiao commentTime">{{ z.CreateDate }}</span>
                                                    </div>
                                                </li>
                                            </template>
                                        </ul>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
                <a href="javascript:void(0)" style="display: none;">
                    <i class="el-icon-arrow-down"></i>
                </a>
            </div>
            <div class="commentBox" v-if="isBusinessProcessId">
                <div class="textarea iconfont iconbianji">
                    <textarea placeholder="请输入评论。。。" v-model="comment"></textarea>
                </div>
                <form>
                    <input type="button" value="发送" @click="submitComments">
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import './index.styl';
import { setTimeout } from 'timers';

export default {
    // 流程模块
    name: 'process-area',
    data() {
        return {
            comment: '',
            btnHide: false,
            isBusinessProcessId: false,
            showTips: false,
            // 是否有未读的提示
            isUnread: false,
            isShowTips: false,
        };
    },
    props: {
        currentViewData: Object,
    },
    created() {},
    updated() {
        this.isScroll();
    },
    watch: {
        'currentViewData.currentBusinessProcess'(newVal) {
            if (newVal && newVal.BusinessProcessId) {
                this.isBusinessProcessId = true;
            } else {
                this.isBusinessProcessId = false;
            }
        },
        'currentViewData.businessRemindList'(newVal) {
            const row = (newVal || []).filter(item => item.RecordStatus === 0);
            if (row.length > 0) {
                this.isUnread = true;
            }
        },
        isUnread(newVal) {
            if (newVal && this.currentViewData.businessRemindList.length > 0) {
                this.isShowTips = true;
                this.showTips = true;
            }
        },
    },
    methods: {
        // 打开流程图
        openflowChart() {
            this.$toast({
                title: true,
                text: '审批流程',
                width: '13rem',
                height: 'auto',
                type: 'eject',
                t_url: 'businessInstance/processArea/flowChart',
                extr: {
                    businessInstanceId: this.currentViewData.businessInstanceId,
                },
                viewPosition: 'components',
            });
        },
        // 流程提示已读
        async flowPathTipsRead() {
            if (this.isUnread) {
                const row = await this.flowPathTipsReadRequest(this.currentViewData.businessInstanceId);
                if (row) {
                    this.currentViewData.businessRemindList.forEach(item => {item.RecordStatus = 1;});
                    this.isUnread = false;
                }
                this.isShowTips = false;
                this.showTips = false;
            } else {
                this.isShowTips = false;
                this.showTips = false;
            }
        },
        // 发送评论
        submitComments() {
            const _that = this;
            if (this.comment) {
                this.$axios
                    .post('/interfaceApi/basicplatform/simpleflow/businessinstance/businesscomment', {
                        BusinessInstanceId: this.currentViewData.businessInstanceId,
                        BusinessProcessId: this.currentViewData.currentBusinessProcess.BusinessProcessId,
                        Content: this.comment,
                    })
                    .then(res => {
                        this.currentViewData.historyNewRecordList.forEach(item => {
                            const row = item.data.filter(k => k.BusinessProcessId === this.currentViewData.currentBusinessProcess.BusinessProcessId);
                            if (row.length > 0) {
                                if (!row[0].CommentList) {
                                    row[0].CommentList = [];
                                }
                                row[0].CommentList.push(res);
                            }
                        });
                        _that.comment = '';
                        setTimeout(function () {
                            _that.isScroll();
                        }, 0);
                    })
                    .catch(error => {
                        _that.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message({
                    message: '请填写评论！',
                    type: 'warning',
                });
            }

        },
        // 计算流程区域滚动条
        isScroll() {
            const oDom = this.$('.processArea #flowChart');
            const aHeight = this.$('.processArea #flowChart .flowChartList').height();
            const sHeight = oDom.height();
            if (aHeight > sHeight) {
                oDom.get(0).scrollTop = oDom.get(0).scrollHeight;
            }
        },

        flowPathTipsReadRequest(businessInstanceId) {
            const path = `/interfaceApi/basicplatform/simpleflow/businessinstance/batch_click_businessremind/${businessInstanceId}`;
            return this.$axios.$put(path, { defEx: true });
        },
    },
};
</script>

<style lang="stylus" scoped>
</style>
